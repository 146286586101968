<template>
  <div>
    <v-alert v-if="success" border="bottom" color="success" dark>
      {{ successMensaje }}
    </v-alert>
    <v-alert v-if="error" border="bottom" color="red" dark>
      <b>Ocurrió un error al guardar la Operatoria.</b> {{ errorMensaje }}
    </v-alert>
    <v-form v-model="valid" ref="form">
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="nombre"
            :rules="nameRules"
            filled
            label="Nombre"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="fideicomiso"
            :items="fideicomisos"
            filled
            required
            label="Unidad de gestión"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="tiposervicio"
            :items="tiposervicios"
            filled
            required
            label="Tipo Operatoria"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-textarea
            :rules="nameRules"
            v-model="descripcion"
            label="Descripción"
            filled
            required
            name="input-7-4"
          ></v-textarea>
        </v-col>
        <!-- <v-col cols="12" md="4">
          <v-file-input
            show-size
            label="Imagen"
            v-model="imagen"
            hint="Archivos .png .jpg .jpeg"
            persistent-hint
            filled
          ></v-file-input>
          <img
            :aspect-ratio="16 / 9"
            width="375"
            contain
            v-if="editar && okImagen"
            :src="verImagen"
          />
        </v-col> -->
        <v-col cols="12" md="4">
          <v-file-input
            :disabled="false"
            show-size
            label="Reglamento y bases"
            v-model="bases"
            filled
            hint="Archivos .pdf .docx"
            persistent-hint
            accept=".pdf,.docx"
          ></v-file-input>
          <v-btn
            class="primary mt-5 ml-5"
            v-if="editar && this.bases !== ''"
            :href="verBases"
            target="_blank"
          >
            Ver Documento
          </v-btn>
        </v-col>
        <v-col md="2">
          <v-checkbox label="Activa" v-model="activar" color="success">
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="2">
          <v-btn
            v-if="!editar"
            color="primary"
            x-large
            :loading="loading"
            @click="guardar"
          >
            Guardar</v-btn
          >
          <v-btn
            v-if="editar"
            color="warning"
            x-large
            :loading="loading"
            @click="modificar"
          >
            Editar</v-btn
          >
        </v-col>
        <v-col md="2">
          <v-btn color="success" x-large @click="listado">Ver Listado</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { API_URL } from '@/common/config';

const axios = require('axios');

export default {
  name: 'NuevoFideicomiso',
  data: () => ({
    status: false,
    activar: false,
    loadingImage: false,
    successMensaje: '',
    errorMensaje: '',
    success: false,
    error: false,
    loading: false,
    valid: false,
    nombre: '',
    descripcion: '',
    imagen: [],
    bases: [],
    base: '',
    fideicomisos: [],
    fideicomiso: '',
    tiposervicios: [],
    tiposervicio: '',
    imageURL: [],
    okImagen: true,
    nameRules: [(v) => !!v || 'El campo es obligatorio'],
  }),
  props: ['editar'],
  computed: {
    verImagen() {
      let img = '';
      if (this.editar && this.imagen !== null) {
        img = this.imagen;
      } else {
        this.hideImg(false);
      }
      return img;
    },
    verBases() {
      let b = '';
      if (this.editar && this.bases !== null) {
        b = this.base;
      }
      return b;
    },
  },
  watch: {
    status(status) {
      this.activar = this.status; // actualiza activar cuando status cambia
    },
  },
  methods: {
    resetForm() {
      this.$refs.form.reset();
    },
    imageLoaded() {
      this.loadingImage = false;
    },
    loadImage() {
      if (this.imageURL !== '') {
        this.loadingImage = 'primary';
      }
    },
    hideImg(valor) {
      this.okImagen = valor;
    },
    listado() {
      this.$router.push(`/listado/${this.$route.params.model}/`);
    },
    async loadFideicomisos() {
      const url = `${API_URL}api/admin/unidadGestion`;
      const header = {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: localStorage.getItem('token'),
        },
      };
      await axios
        .get(url, header)
        .then((response) => {
          if (response.data.status === 'success') {
            this.fideicomisos = response.data.fideicomisos.map((f) => ({
              value: f.id,
              text: f.nombre,
            }));
          } else {
            console.log('Error al obtener unidad de gestion');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async guardar() {
      this.loading = true;
      this.error = false;
      const url = `${API_URL}api/${this.$route.params.model}/`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      const formData = new FormData();
      formData.append('nombre', this.nombre);
      formData.append('descripcion', this.descripcion);
      formData.append('fideicomiso_id', this.fideicomiso);
      formData.append('tiposervicio', this.tiposervicio);
      formData.append('status', this.activar);
      // formData.append('adjunto', this.imagen);
      if (this.bases !== null) {
        formData.append('adjunto', this.bases);
      }
      await axios.post(url, formData, header).then((response) => {
        if (response.data.status === 'success') {
          this.loading = false;
          this.success = true;
          this.successMensaje = response.data.message;
          this.resetForm();
        } else if (response.data.status === 'error') {
          this.error = true;
          this.errorMensaje = response.data.message;
          this.loading = false;
        }
      });
    },
    async modificar() {
      this.loading = true;
      const url = `${API_URL}api/servicio/editar/`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      const formData = new FormData();
      formData.append('id', this.$route.params.id);
      formData.append('nombre', this.nombre);
      formData.append('descripcion', this.descripcion);
      formData.append('fideicomiso_id', this.fideicomiso);
      formData.append('tiposervicio', this.tiposervicio);
      formData.append('status', this.activar);
      // if (this.imagen !== null) {
      //   formData.append('adjunto', this.imagen);
      // }
      if (this.bases !== null) {
        formData.append('adjunto', this.bases);
      }
      await axios.post(url, formData, header).then((response) => {
        if (response.data.status === 'success') {
          this.error = false;
          this.$router.push(`/listado/${this.$route.params.model}/`);
        } else if (response.data.status === 'error') {
          this.error = true;
          this.errorMensaje = response.data.message;
          this.loading = false;
        }
      });
    },
    presentarDatos() {
      axios
        .get(`${API_URL}api/servicio/${this.$route.params.id}`, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.nombre = response.data.lineaCredito.nombre;
          this.fideicomiso = response.data.lineaCredito.fideicomiso_id.id;
          this.descripcion = response.data.lineaCredito.descripcion;
          this.tiposervicio = response.data.lineaCredito.tipo_servicio_id;
          this.imagen = response.data.lineaCredito.imagenURL;
          this.bases = response.data.lineaCredito.bases;
          this.base = this.bases;
          this.status = response.data.lineaCredito.status;
        });
    },
    async loadServicios() {
      const url = `${API_URL}api/tipoServicios`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      await axios
        .get(url, header)
        .then((response) => {
          if (response.data.status === 'success') {
            this.tiposervicios = response.data.tipoServicios.map((f) => ({
              value: f.id,
              text: f.nombre,
            }));
          } else {
            console.log('Error al obtener la operatoria');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    if (this.editar) {
      this.loadFideicomisos();
      this.loadServicios();
      this.presentarDatos();
    } else {
      this.loadFideicomisos();
      this.loadServicios();
    }
  },
};
</script>
