<template>
    <div>
     <v-alert v-if="success"
      border="bottom"
      color="success"
      dark
    >
      {{ successMensaje }}
    </v-alert>
    <v-alert v-if="error"
      border="bottom"
      color="red"
      dark
    >
      <b>Ocurrió un error al guardar la Notificacion. </b> {{ errorMensaje }}
    </v-alert>
  <v-form v-model='valid' ref="form">
    <v-row>
      <v-col cols='12' md='4'>
    <v-text-field
          v-model='proceso'
          :rules='nameRules'
          label='Proceso'
          required
          filled
        ></v-text-field>
      </v-col>
      <v-col cols='12' md='4'>
    <v-text-field
      v-model='condicion'
      :rules='nameRules'
      label='Condición'
      required
      filled
    ></v-text-field>
      </v-col>
      <v-col cols='12' md='4'>
    <v-autocomplete
      v-model='notifi'
      :items='notifies'
      required
      label='Config Notificación'
      filled
    ></v-autocomplete>
    </v-col>
    </v-row>
    <v-row >
      <v-col v-if='!editar' cols="6" md="2" >
      <v-btn color="primary" @click="addRol">Nuevo Rol</v-btn>
     </v-col>
      <v-col cols="8" md="5">
    <div v-for="(textField, id) in textFields"
     :key="id" class="text-fields-row" >
        <v-autocomplete
          v-model='textField.rol'
          :items='roles'
          required
          label='Rol'
          filled
        ></v-autocomplete>
        <v-btn v-if='i >= 2' class="mx-2" small fab dark color="red" @click="removeRoll(id)">
        <v-icon dark> mdi-minus </v-icon></v-btn>
    </div>
    </v-col>
    </v-row>
    <v-row>
      <v-col md="2">
        <v-btn v-if='!editar' color="primary" x-large :loading="loading" @click="guardar">
          Guardar
        </v-btn>
        <v-btn v-if='editar' color="warning" x-large :loading="loading" @click="modificar">
          Editar
        </v-btn>
      </v-col>
      <v-col md="2">
      <v-btn color="success" x-large
           @click="listado">Ver Listado</v-btn>
      </v-col>
    </v-row>
  </v-form>
  </div>
</template>
<script>
import { API_URL } from '@/common/config';

import axios from 'axios';

export default {
  data: () => ({
    loading: false,
    success: false,
    error: false,
    successMensaje: '',
    errorMensaje: '',
    valid: false,
    estado: '',
    rol: '',
    roles: [],
    proceso: '',
    condicion: '',
    i: 1,
    notifi: '',
    notifies: [],
    textFields: [{ rol: '' }],
    nameRules: [(v) => !!v || 'El campo es obligatorio'],
  }),
  props: [
    'editar',
  ],
  methods: {
    resetForm() {
      this.$refs.form.reset();
      for (let index = 0; index <= this.i; index += 1) {
        this.textFields.splice(index, 1);
      }
      this.i = 1;
    },
    async guardar() {
      this.loading = true;
      const data = {
        proceso: this.proceso,
        condicion: this.condicion,
        notifi_id: this.notifi,
        rol_id: this.textFields,
      };
      const url = `${API_URL}api/cfgNotificacion`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      await axios.post(url, data, header).then((response) => {
        if (response.data.status === 'success') {
          this.loading = false;
          this.success = true;
          this.successMensaje = response.data.message;
          this.resetForm();
        } else if (response.data.status === 'error') {
          this.error = true;
          this.errorMensaje = response.data.message;
          this.loading = false;
        }
      });
    },
    async modificar() {
      this.loading = true;
      axios
        .post(`${API_URL}api/cfgNotificacion/editar/`, {
          token: localStorage.getItem('token'),
          cfgNoti_id: this.$route.params.id,
          proceso: this.proceso,
          condicion: this.condicion,
          notifi_id: this.notifi,
          rol_id: this.textFields,
        })
        .then((response) => {
          if (response.data.status === 'success') {
            this.loading = false;
            this.success = true;
            this.successMensaje = response.data.message;
            this.$router.push(`/listado/${this.$route.params.model}`);
          } else if (response.data.status === 'error') {
            this.error = true;
            this.errorMensaje = response.data.message;
            this.loading = false;
          }
        });
    },
    listado() {
      this.$router.push(`/listado/${this.$route.params.model}/`);
    },
    presentarDatos() {
      axios
        .get(
          `${API_URL}api/cfgNotificacion/${this.$route.params.id}`,
          {
            headers: {
              token: localStorage.getItem('token'),
            },
          },
        )
        .then((response) => {
          console.log('hola');
          console.log(response);
          this.proceso = response.data.cfgNotificacion.proceso;
          this.condicion = response.data.cfgNotificacion.condicion;
          this.notifi = response.data.cfgNotificacion.cfgNotificacionMsg_id;
          this.textFields.splice(0, 1);
          this.textFields.push({
            rol: response.data.cfgNotificacion.rol_id,
          });
          // this.textField = response.data.cfgNotificacion.rol_id;
        });
    },
    async loadRoles() {
      const url = `${API_URL}api/roles`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      await axios
        .get(url, header)
        .then((response) => {
          if (response.data.status === 'success') {
            this.roles = response.data.roles.map((f) => ({
              value: f.id,
              text: f.nombre,
            }));
          } else {
            console.log('Error al obtener la operatoria');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async loadCfgMsg() {
      const url = `${API_URL}api/cfgNotificacionMsg`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      await axios
        .get(url, header)
        .then((response) => {
          console.log(response);
          if (response.data.status === 'success') {
            this.notifies = response.data.cfgNotificacionMsg.map((f) => ({
              value: f.id,
              text: f.descripcion,
            }));
          } else {
            console.log('Error al obtener la configuración');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addRol() {
      this.i += 1;
      this.textFields.push({
      });
    },
    removeRoll(index) {
      this.i -= 1;
      this.textFields.splice(index, 1);
    },
  },
  created() {
    this.loadCfgMsg();
    this.loadRoles();
    if (this.editar) {
      this.loadCfgMsg();
      this.loadRoles();
      this.presentarDatos();
    }
  },
};
</script>
<style>
.text-fields-row {
  display: flex;
}
</style>
