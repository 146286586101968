<template>
    <div>
      <v-row>
      <v-col v-for="item in tipoParametros"
        cols="6"
        :key="item.id">
          <v-select
            v-if="item.options !== 'int' &&
            item.options !== 'float' &&
            item.options !== 'string' &&
            item.options !== 'percent'"
                v-model="item.model"
                :items ="item.opciones"
                :label="item.nombre"
                required
          ></v-select>
          <v-text-field-money
                v-if="item.options === 'int'"
                v-model="item.model"
                :rules="[rules.required, rules.number]"
                :label="item.nombre"
                v-bind:properties="{
                  readonly: false,
                  disabled: false,
                  outlined: false,
                  clearable: true,
                  placeholder: '',
                }"
                v-bind:options="{
                  locale: 'pt-BR',
                  length: 15,
                  precision: 0,
                  empty: true,
                }"
            ></v-text-field-money>
            <v-text-field-money
                  v-if="item.options === 'float'"
                  v-model="item.model"
                  :rules="[rules.required, rules.number]"
                  :label="item.nombre"
                  v-bind:properties="{
                    prefix: '$',
                    readonly: false,
                    disabled: false,
                    outlined: false,
                    clearable: true,
                    placeholder: '',
                  }"
                  v-bind:options="{
                    locale: 'pt-BR',
                    length: 15,
                    precision: 2,
                    empty: null,
                  }"
              ></v-text-field-money>
            <v-text-field-percent
                  v-if="item.options === 'percent'"
                  v-model="item.model"
                  :rules="[rules.required, rules.number]"
                  :label="item.nombre"
                  v-bind:properties="{
                    prefix: '%',
                    readonly: false,
                    disabled: false,
                    outlined: false,
                    clearable: true,
                    placeholder: '',
                  }"
                  v-bind:options="{
                    locale: 'es-AR',
                    length: 4,
                    precision: 2,
                    empty: null,
                  }"
              ></v-text-field-percent>
              <v-text-field
                  v-if="item.options === 'string'"
                  v-model="item.model"
                  :label="item.nombre"
              ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='4'>
          <v-btn v-on:click="guardarParametros" x-large class="ml-5 mt-5 mb-5" color='success'>
              Confirmar Datos
          </v-btn>
        </v-col>
        <v-col cols="8">
          <v-alert v-if="success"
            border="bottom"
            color="success"
            dark
          >
            {{ successMensaje }}
          </v-alert>
          <v-alert v-if="error"
            border="bottom"
            color="red"
            dark
          >
            <b>Ocurrió un error al guardar el Parametro de la operatoria.</b> {{ errorMensaje }}
          </v-alert>
        </v-col>
      </v-row>
    </div>
</template>
<script>
import axios from 'axios';
import { API_URL } from '@/common/config';

export default {
  props: {
    lineaCredito_id: {
      type: Number,
      default: null,
    },
    userId: {
      type: Number,
    },
    lineacredito: {
      type: Number,
    },
    tipo_parametro: {
      type: Number,
    },
  },
  data: () => ({
    tipoParametros: [],
    rules: {
      required: (value) => !!value || 'Campo requerido',
      number: (value) => /[0-9]+/.test(value) || 'Ingrese un número entero.-',
    },
    loading: false,
    success: false,
    error: false,
    successMensaje: '',
    errorMensaje: '',
  }),
  methods: {
    guardarParametros() {
      this.success = false;
      this.error = false;
      this.successMensaje = '';
      this.errorMensaje = '';
      const tipoParametros = this.tipoParametros.map((el) => {
        if (el.options === 'percent') {
          return { ...el, model: el.model / 100 };
        }
        return el;
      });
      const aux = tipoParametros.map((el) => [el.clave, el.model]);
      const parametros = Object.fromEntries(aux);
      const keys = Object.keys(parametros);
      const values = Object.values(parametros);
      const valores = values.map((el) => Number.parseFloat(el, 10));
      const data = this.generarData(keys, valores, this.lineaCredito_id);
      this.postServicioParametros(data);
      this.successMensaje = 'Los datos fueros cargados con exito';
    },
    generarData(keys, values, id, acum = []) {
      if (keys.length === 0 && values.length === 0) return acum;
      const [key, ...arrKeys] = keys;
      const [value, ...arrValues] = values;
      const data = { clave: key, valor: value, lineaCredito_id: id };
      const acumulado = acum.concat(data);
      return this.generarData(arrKeys, arrValues, id, acumulado);
    },
    async postServicioParametros(array) {
      const url = `${API_URL}api/servicioParametros`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      if (array.length === 0) return 'Parametros cargados';
      const [data, ...arr] = array;
      await axios.post(url, data, header).then((response) => {
        if (response.data.status === 'error') {
          this.errorMessage = response.data.message;
        } else {
          this.success = true;
          this.successMessage = response.data.message;
        }
      });
      return this.postServicioParametros(arr);
    },
    async getTipoParametro() {
      const tipo = this.tipo_parametro || 13;
      axios.get(
        `${API_URL}api/tipoParametros`,
        {
          headers: {
            token: localStorage.getItem('token'),
          },
        },
      )
        .then((response) => {
          const data = response.data.tipoParametros
            .filter((el) => el.tipo_servicio_id.id === tipo
            && el.options !== null && el.destino === 'operatoria');
          const prueba = data
            .map((el) => {
              if (el.options !== 'int' && el.options !== 'string' && el.options !== 'float' && el.options !== 'percent') {
                return JSON.parse(el.options);
              }
              return el.options;
            });
          this.tipoParametros = data.map((el, index) => ({
            ...el,
            opciones: prueba[index],
            model: 0,
          }));
          this.parametrosCargados = true;
          this.getServicioParametro();
        });
    },
    async getServicioParametro() {
      await axios.get(
        `${API_URL}api/servicioParametros/operatoria/${this.lineaCredito_id}`,
        {
          headers: {
            token: localStorage.getItem('token'),
          },
        },
      )
        .then((response) => {
          const data = response.data.parametros;
          data
            .map((el) => this.tipoParametros
              .map((elem, index) => {
                if (el.tipo_parametro_id !== null) {
                  if (elem.clave === el.tipo_parametro_id.clave) {
                    if (elem.options === 'percent') {
                      this.tipoParametros[index].model = el.valor * 100;
                    } else {
                      this.tipoParametros[index].model = el.valor;
                    }
                    return true;
                  }
                }
                return false;
              }));
        });
    },
  },
  created() {
    this.getTipoParametro();
  },
};
</script>
