var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',_vm._l((_vm.tipoParametros),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"6"}},[(item.options !== 'int' &&
        item.options !== 'float' &&
        item.options !== 'string' &&
        item.options !== 'percent')?_c('v-select',{attrs:{"items":item.opciones,"label":item.nombre,"required":""},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}}):_vm._e(),(item.options === 'int')?_c('v-text-field-money',{attrs:{"rules":[_vm.rules.required, _vm.rules.number],"label":item.nombre,"properties":{
              readonly: false,
              disabled: false,
              outlined: false,
              clearable: true,
              placeholder: '',
            },"options":{
              locale: 'pt-BR',
              length: 15,
              precision: 0,
              empty: true,
            }},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}}):_vm._e(),(item.options === 'float')?_c('v-text-field-money',{attrs:{"rules":[_vm.rules.required, _vm.rules.number],"label":item.nombre,"properties":{
                prefix: '$',
                readonly: false,
                disabled: false,
                outlined: false,
                clearable: true,
                placeholder: '',
              },"options":{
                locale: 'pt-BR',
                length: 15,
                precision: 2,
                empty: null,
              }},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}}):_vm._e(),(item.options === 'percent')?_c('v-text-field-percent',{attrs:{"rules":[_vm.rules.required, _vm.rules.number],"label":item.nombre,"properties":{
                prefix: '%',
                readonly: false,
                disabled: false,
                outlined: false,
                clearable: true,
                placeholder: '',
              },"options":{
                locale: 'es-AR',
                length: 4,
                precision: 2,
                empty: null,
              }},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}}):_vm._e(),(item.options === 'string')?_c('v-text-field',{attrs:{"label":item.nombre},model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}}):_vm._e()],1)}),1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"ml-5 mt-5 mb-5",attrs:{"x-large":"","color":"success"},on:{"click":_vm.guardarParametros}},[_vm._v(" Confirmar Datos ")])],1),_c('v-col',{attrs:{"cols":"8"}},[(_vm.success)?_c('v-alert',{attrs:{"border":"bottom","color":"success","dark":""}},[_vm._v(" "+_vm._s(_vm.successMensaje)+" ")]):_vm._e(),(_vm.error)?_c('v-alert',{attrs:{"border":"bottom","color":"red","dark":""}},[_c('b',[_vm._v("Ocurrió un error al guardar el Parametro de la operatoria.")]),_vm._v(" "+_vm._s(_vm.errorMensaje)+" ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }