<template>
<div>
   <v-alert v-if="success"
      border="bottom"
      color="success"
      dark
    >
      {{ successMensaje }}
    </v-alert>
    <v-alert v-if="error"
      border="bottom"
      color="red"
      dark
    >
      <b>Ocurrió un error al guardar la unidad de gestión.</b> {{ errorMensaje }}
    </v-alert>
    <v-form v-model='valid' ref="form">
    <v-row>
       <v-col cols='12' md='4'>
        <v-text-field
          v-model='nombre'
          :rules='nameRules'
          filled
          label='Nombre'
          required
        ></v-text-field>
      </v-col>
      <v-col cols='12' md='4'>
        <v-text-field
          v-model='descripcion'
          :rules='nameRules'
          label='Descripción'
          required
          filled
        ></v-text-field>
      </v-col>
      <v-col cols='12' md='4'>
         <v-select
         required
         filled
         v-model="sectorProductivo"
          :items="sectoresProductivos"
          label="Sector Productivo"
        ></v-select>
      </v-col>
       <v-col cols='12' md='4'>
       <v-file-input
          show-size
          filled
          label="Imagen"
          v-model= "imagen"
        ></v-file-input>
        <img v-if="editar" :src="verImagen">
      </v-col>
      <v-col md="2">
        <v-btn v-if='!editar' color="primary" x-large :loading="loading" @click="guardar">
          Guardar
        </v-btn>
        <v-btn v-if='editar' color="primary" x-large :loading="loading" @click="modificar">
          Editar
        </v-btn>
      </v-col>
      <v-col md="2">
      <v-btn color="success" x-large
           @click="listado">Ver Listado</v-btn>
      </v-col>
    </v-row>
  </v-form>
  </div>
</template>
<script>
import { API_URL } from '@/common/config';

const axios = require('axios');

export default {
  name: 'NuevoFideicomiso',
  data: () => ({
    loadingImage: false,
    successMensaje: '',
    errorMensaje: '',
    success: false,
    error: false,
    loading: false,
    valid: false,
    nombre: '',
    descripcion: '',
    imagen: [],
    imagenBase: '',
    sectorProductivo: '',
    sectoresProductivos: [],
    nameRules: [(v) => !!v || 'El campo es obligatorio'],
  }),
  props: [
    'editar',
  ],
  computed: {
    verImagen() {
      let img = '';
      if (this.editar) {
        img = `${API_URL}/fideicomisos/${this.imagenBase}`;
      }
      return img;
    },
  },
  methods: {
    resetForm() {
      this.$refs.form.reset();
    },
    imageLoaded() {
      this.loadingImage = false;
    },
    loadImage() {
      if (this.imageURL !== '') {
        this.loadingImage = 'primary';
      }
    },
    async guardar() {
      this.loading = true;
      const url = `${API_URL}api/${this.$route.params.model}/`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      const formData = new FormData();
      formData.append('nombre', this.nombre);
      formData.append('descripcion', this.descripcion);
      formData.append('sectorProductivo', this.sectorProductivo);
      formData.append('adjunto', this.imagen);
      await axios.post(url, formData, header).then((response) => {
        if (response.data.status === 'success') {
          this.success = true;
          this.successMensaje = response.data.message;
          this.resetForm();
          this.imageLoaded();
        } else if (response.data.status === 'error') {
          this.error = true;
          this.errorMensaje = response.data.message;
        }
        this.loading = false;
      });
    },
    async modificar() {
      this.loading = true;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      const formData = new FormData();
      formData.append('fideicomiso_id', this.$route.params.id);
      formData.append('nombre', this.nombre);
      formData.append('descripcion', this.descripcion);
      formData.append('sectorProductivo', this.sectorProductivo);
      formData.append('adjunto', this.imagen);
      axios
        .post(`${API_URL}api/unidadGestion/editar/`, formData, header)
        .then((response) => {
          if (response.data.status === 'success') {
            this.$router.push(`/listado/${this.$route.params.model}/`);
          }
          this.loading = false;
        });
    },
    listado() {
      this.$router.push(`/listado/${this.$route.params.model}/`);
    },
    presentarDatos() {
      axios
        .get(
          `${API_URL}api/unidadGestion/${this.$route.params.id}`,
          {
            headers: {
              token: localStorage.getItem('token'),
            },
          },
        )
        .then((response) => {
          this.nombre = response.data.fideicomiso.nombre;
          this.descripcion = response.data.fideicomiso.descripcion;
          this.sectorProductivo = response.data.fideicomiso.sectorProductivo.id;
          this.imagenBase = response.data.fideicomiso.imagenURL;
        });
    },
    loadSectorProductivo() {
      axios
        .get(
          `${API_URL}api/sectoresProductivos`,
          {
            headers: {
              token: localStorage.getItem('token'),
            },
          },
        )
        .then((response) => {
          this.sectoresProductivos = response.data.sectoresProductivos.map((f) => ({
            value: f.id,
            text: f.nombre,
          }));
        });
    },
  },
  created() {
    if (this.editar) {
      this.presentarDatos();
    }
    this.loadSectorProductivo();
  },
};
</script>
