<template>
    <div>
     <v-alert v-if="success"
      border="bottom"
      color="success"
      dark
    >
      {{ successMensaje }}
    </v-alert>
    <v-alert v-if="error"
      border="bottom"
      color="red"
      dark
    >
      <b>Ocurrió un error al guardar el mensaje. </b> {{ errorMensaje }}
    </v-alert>
  <v-form v-model='valid' ref="form">
    <v-row>
      <v-col cols='12' md='12'>
        <v-text-field
          v-model='descripcion'
          :rules='nameRules'
          label='Descripción'
          required
          filled
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="2">
        <v-btn v-if='!editar' color="primary" x-large :loading="loading" @click="guardar">
          Guardar
        </v-btn>
        <v-btn v-if='editar' color="warning" x-large :loading="loading" @click="modificar">
          Editar
        </v-btn>
      </v-col>
      <v-col md="2">
      <v-btn color="success" x-large
           @click="listado">Ver Listado</v-btn>
      </v-col>
    </v-row>
  </v-form>
  </div>
</template>
<script>
import { API_URL } from '@/common/config';

import axios from 'axios';

export default {
  data: () => ({
    loading: false,
    success: false,
    error: false,
    successMensaje: '',
    errorMensaje: '',
    valid: false,
    descripcion: '',
    nameRules: [(v) => !!v || 'El campo nombre es obligatorio'],
  }),
  props: [
    'editar',
  ],
  methods: {
    resetForm() {
      this.$refs.form.reset();
    },
    async guardar() {
      this.loading = true;
      const data = {
        descripcion: this.descripcion,
      };
      const url = `${API_URL}api/cfgNotificacionMsg`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      await axios.post(url, data, header).then((response) => {
        if (response.data.status === 'success') {
          this.loading = false;
          this.success = true;
          this.successMensaje = response.data.message;
          this.resetForm();
        } else if (response.data.status === 'error') {
          this.error = true;
          this.errorMensaje = response.data.message;
          this.loading = false;
        }
      });
    },
    async modificar() {
      this.loading = true;
      axios
        .post(`${API_URL}api/cfgNotificacionMsg/editar/`, {
          token: localStorage.getItem('token'),
          cfgMsg_id: this.$route.params.id,
          descripcion: this.descripcion,
        })
        .then((response) => {
          if (response.data.status === 'success') {
            this.loading = false;
            this.success = true;
            this.successMensaje = response.data.message;
            this.$router.push(`/listado/${this.$route.params.model}`);
          } else if (response.data.status === 'error') {
            this.error = true;
            this.errorMensaje = response.data.message;
            this.loading = false;
          }
        });
    },
    listado() {
      this.$router.push(`/listado/${this.$route.params.model}/`);
    },
    presentarDatos() {
      axios
        .get(
          `${API_URL}api/cfgNotificacionMsg/${this.$route.params.id}`,
          {
            headers: {
              token: localStorage.getItem('token'),
            },
          },
        )
        .then((response) => {
          this.descripcion = response.data.cfgNotiMsg[0].descripcion;
        });
    },
  },
  created() {
    if (this.editar) {
      this.presentarDatos();
    }
  },
};
</script>
