<template>
  <div>
      <v-alert v-if='success' border='bottom' color='success' dark>
      {{ successMensaje }}
    </v-alert>
    <v-alert v-if='error' border='bottom' color='red' dark>
      <b>Ocurrió un error al guardar el Documento.</b> {{ errorMensaje }}
    </v-alert>
    <v-form v-model='valid' ref='form'>
        <v-row>
        <v-col cols='12' md='6'>
            <v-text-field
          v-model='nombre'
          filled
          label='Nombre'
          required
        ></v-text-field>
        </v-col>
        <v-col cols='12' md='6'>
            <v-text-field
          v-model='descripcion'
          filled
          label='Descripción'
          required
        ></v-text-field>
        </v-col>
        <v-col cols='12' md='6'>
          <v-select
            label='Tipo Análisis'
            v-model='tipo_analisis'
            :items="[{value: 'Patrimonial', text: 'Patrimonial'},
            {value: 'Técnico', text: 'Técnico'},
            {value: 'Legal', text: 'Legal'},
            {value: 'Garantía', text: 'Garantía'}, {value: 'General', text: 'General'}]"
            >
          </v-select>
        </v-col>
        </v-row>
      <v-row>
        <v-col md="2">
          <v-btn v-if='!editar' color='primary' x-large :loading='loading'
           @click="guardar">Guardar</v-btn>
          <v-btn v-if='editar' color='warning' x-large :loading='loading'
           @click="modificar">Editar</v-btn>
        </v-col>
         <v-col md="2">
            <v-btn color="success" x-large
            @click="listado">Ver Listado</v-btn>
      </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { API_URL } from '@/common/config';

import axios from 'axios';

export default {
  name: 'Documento',
  data: () => ({
    success: false,
    error: false,
    valid: false,
    loading: false,
    successMensaje: 'El documento se guardó con exito',
    errorMensaje: '',
    nombre: '',
    descripcion: '',
    tipo_analisis: '',
  }),
  props: [
    'editar',
  ],
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    resetForm() {
      this.$refs.form.reset();
    },
    guardar() {
      this.loading = true;
      const url = `${API_URL}api/documentoMaestros/`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      const data = {
        nombre: this.nombre,
        descripcion: this.descripcion,
        tipo_analisis: this.tipo_analisis,
      };
      axios.post(url, data, header)
        .then((response) => {
          if (response.data.status === 'success') {
            this.loading = false;
            this.success = true;
            this.error = false;
            this.resetForm();
          } else {
            this.loading = false;
            this.error = true;
            this.errorMensaje = response.data.message;
          }
        }).catch((error) => {
          console.log(error);
          this.error = true;
        });
    },
    async modificar() {
      this.loading = true;
      axios
        .post(`${API_URL}api/documentoMaestros/editar/`, {
          token: localStorage.getItem('token'),
          id: this.$route.params.id,
          nombre: this.nombre,
          descripcion: this.descripcion,
          tipo_analisis: this.tipo_analisis,
        })
        .then((response) => {
          if (response.data.status === 'success') {
            this.$router.push(`/listado/${this.$route.params.model}/`);
          }
          this.loading = false;
        });
    },
    listado() {
      this.$router.push(`/listado/${this.$route.params.model}/`);
    },
    presentarDatos() {
      axios
        .get(
          `${API_URL}api/documentoMaestros/${this.$route.params.id}`,
          {
            headers: {
              token: localStorage.getItem('token'),
            },
          },
        )
        .then((response) => {
          this.nombre = response.data.documentoMaestro.nombre;
          this.descripcion = response.data.documentoMaestro.descripcion;
          this.tipo_analisis = response.data.documentoMaestro.tipo_analisis;
        });
    },
  },
  created() {
    if (this.editar) {
      this.presentarDatos();
    }
  },
};
</script>
